import {useEffect, useState, useCallback, useRef} from "react";
import axios from "axios";

import {useWindow} from "context/windowContext";
import {useCustomer} from "context/customerContext";

const useChat = (props) => {
    const {window} = useWindow();
    const {customerData} = useCustomer();

    const [localChatID, setLocalChatID] = useState(null);
    const [messages, setMessages] = useState([]);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [isTreatment, setIsTreatment] = useState(false);
    const [isMessageSending, setIsMessageSending] = useState(false);
    const fileRef = useRef(null);
    const lastMessageRef = useRef(null);

    const admin = (messages?.users || []).find((user) => user.role === "admin");
    const user = (messages?.users || []).find((user) => user.role === "end-user");

    const getChat = useCallback(() => {
        if (customerData?.id && localChatID) {
            axios
                .get("/api/zendesk/chat/getmessages", {
                    params: {
                        customer_id: customerData.id,
                        type: localChatID,
                    },
                })
                .then((response) => {
                    if (response?.data[localChatID]) {
                        setMessages(response?.data[localChatID]);
                        setIsChatLoading(false);
                    }

                    axios.get("/api/zendesk/chat/messagecount/clean", {
                        params: {
                            customer_id: customerData.id,
                            type: localChatID,
                        },
                    }).catch(e => console.error(e))
                })
                .catch((e) => console.error(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localChatID, customerData?.id]);

    const changeMessageHandler = (e) => {
        setTextAreaValue(e.target.value);
    };

    const sendMessageHandler = async (event) => {
        event.preventDefault();

        try {
            const files = event?.target?.file?.files || [];

            const filesArray = await Promise.all(
                Array.from(files).map(async (file) => {
                    const response = await fetch(
                        `/api/vercel/upload?filename=${file.name}`,
                        {
                            method: 'POST',
                            body: file,
                        }
                    );
                    const blob = await response.json();
                    return {
                        name: blob.pathname,
                        url: blob.url,
                        type: file.type
                    };
                })
            );

            axios
                .post("/api/zendesk/chat/sendmessage", {
                    "customer_id": customerData.id,
                    type: localChatID,
                    message: textAreaValue,
                    files: filesArray
                })
                // response param ignored
                .then(() => {
                    setTextAreaValue("");
                    getChat();
                    setIsMessageSending(false);
                    fileRef.current.value = null;
                })
                .catch((e) => console.error(e));

        } catch (error) {
            console.error(error);
        }
    };

    const formatDate = (dateString) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        const date = new Date(dateString);
        return date.toLocaleDateString("nl-NL", options);
    };

    useEffect(() => {
        getChat();
    }, [localChatID, customerData?.id]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    }, [lastMessageRef.current, messages.count]);

    useEffect(() => {
        const delay = lastMessageRef.current ? 10000 : 1000;
        const interval = setInterval(() => {
            getChat();
        }, delay);

        return () => {
            clearInterval(interval);
        };
    }, [getChat, lastMessageRef.current]);

    useEffect(() => {
        const search = window.location.search;
        const searchParams = new URLSearchParams(search);
        const chatId = searchParams.get("chatId");
        const treatment = searchParams.get("isTreatment");
        if (chatId !== localChatID) {
            setLocalChatID(chatId);
            setIsTreatment(!!treatment);
        }
    }, [window?.location?.search, localChatID]);

    return {
        formatDate,
        messages,
        admin,
        user,
        textAreaValue,
        changeMessageHandler,
        sendMessageHandler,
        localChatID,
        fileRef,
        lastMessageRef,
        isChatLoading,
        isTreatment,
        isMessageSending,
    };
};
export default useChat;
