import React, { Fragment, useRef, useState } from "react";
import { Link } from "gatsby";

import useChat from "hooks/useChat";

import Main from "components/Layout/main";
import Loader from "components/UI/loader";
import Image from "components/UI/image";

import DownloadIcon from "images/svg/downloadIcon.svg";
import SendIcon from "images/svg/buttonArrowDark.svg";
import BackIcon from "images/svg/chatBackIcon.svg";

import "styles/chat.scss";

const ChatWrapper = (props) => {
    const {
        formatDate,
        messages,
        admin,
        user,
        textAreaValue,
        changeMessageHandler,
        sendMessageHandler,
        localChatID,
        fileRef,
        lastMessageRef,
        isChatLoading,
        isTreatment,
        isMessageSending,
    } = useChat();

    const defaultFileName = 'Geen bestand gekozen';

    const formRef = useRef(null);
    const textareaRef = useRef(null);
    const [fileName, setFileName] = useState(defaultFileName);

    if (!localChatID) {
        return null;
    }

    if (isChatLoading) {
        return <Loader/>;
    }

    const counterRows = (textAreaValue) => {
        const lines = textAreaValue.split('\n');
        const rowCount = lines.length;

        return rowCount - 1;
    }

    const textareaWidth = +textareaRef?.current?.offsetWidth || 300;
    const rowsCountBreak = counterRows(textAreaValue);
    const rowsCountFullText = Math.ceil(
        (textAreaValue.length || 1) / (textareaWidth / 10)
    );

    const onEnterPress = (event) => {
        if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && !event.altKey) {
            event.preventDefault();
            formRef.current.requestSubmit();
        }
    }
    const handleFileChange = () => {
        const fileNameElement = document.getElementById('file-name');
        if (fileRef.current.files.length > 0) {
            // fileNameElement.value = fileRef.current.files[0].name;
            setFileName(fileRef.current.files[0].name);
        } else {
            setFileName(defaultFileName);
        }
    };

    const handelSubmit = (formValues) => {
        sendMessageHandler(formValues);
        if (fileName !== defaultFileName) {
            setFileName(defaultFileName);
        }
    }

    return (
        <div className="chat-content">
            <Link
                to={
                    isTreatment
                        ? `/my-account/treatments?treatmentId=${localChatID}`
                        : '/my-account'
                }
                className="backButton"
            >
                <Image alt="Back" src={BackIcon}/>
            </Link>
            <div className="messages">
                {(messages?.comments || []).map((message, index, arr) => {
                    let isUser = false

                    if (user !== undefined) {
                        isUser = user.id === message.author_id
                    }

                    const conditionToShowDate =
                        index === 0 ||
                        Date.parse(arr[index].created_at) - Date.parse(arr[index - 1].created_at) > 1800000 ||
                        arr[index].author_id !== arr[index - 1].author_id;

                    return (
                        <Fragment key={index}>
                            {conditionToShowDate && (
                                <p
                                    className={
                                        isUser
                                            ? "message__date message__date--outcome"
                                            : "message__date"
                                    }
                                >
                                    {formatDate(message.created_at)}
                                </p>
                            )}

                            <div
                                key={message.id}
                                className={`${isUser ? "message outcome" : "message"}`}
                                ref={
                                    index === messages.comments.length - 1
                                        ? lastMessageRef
                                        : null
                                }
                            >
                                <p className="message__author">
                                    {isUser ? "Ik" : admin.name}
                                </p>
                                <p
                                    className="message__text"
                                    dangerouslySetInnerHTML={{ __html: message.html_body }}
                                />
                                <div className="message__atachments">
                                    {message.attachments.map((attachment) => (
                                        <a
                                            key={attachment.id}
                                            className={
                                                isUser
                                                    ? "message__atachment--outcome"
                                                    : "message__atachment"
                                            }
                                            href={attachment.content_url}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Image src={DownloadIcon}/>
                                            {attachment.file_name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            <hr></hr>
            <form
                onSubmit={handelSubmit}
                className="chat-form"
                ref={formRef}
            >
                <div className="chat-form__fields">
                    <div className="textarea-wrapper">
                        <textarea
                            placeholder="Uw bericht"
                            name="message"
                            ref={textareaRef}
                            rows={rowsCountBreak + rowsCountFullText}
                            wrap="soft"
                            required
                            className="chat-form__textarea"
                            value={textAreaValue}
                            onChange={changeMessageHandler}
                            onKeyDown={onEnterPress}
                        />
                        <button
                            type="submit"
                            disabled={isMessageSending}
                            className="chat-form__submit"
                        >
                            {isMessageSending ? (
                                <Loader/>
                            ) : (
                                <Image className="chat-form__submit-image" src={SendIcon}/>
                            )}
                        </button>
                    </div>
                    <div className="input-container">
                        <label className="custom-file-input">
                            <span>Kies bestand</span>
                            <input
                                className="file-input"
                                name="file"
                                type="file"
                                multiple
                                ref={fileRef}
                                onChange={handleFileChange}
                            />
                        </label>
                        <input className={"label-file-input"} readOnly id="file-name" value={fileName}/>
                    </div>
                </div>
            </form>
        </div>
    );
};

const Chat = (props) => {
    return (
        <Main isFooter={false}>
            <ChatWrapper {...props}/>
        </Main>
    );
}

export default Chat;
